$text-color: #000;
$accent-text-color: rgba(255, 255, 255, 0.3);

$background-color: #032467;

$accent-font-family: 'Pribambas', 'Helvetica', 'Roboto', -apple-system, sans-serif;

$blue-color: #032467;
$red-color: #B81233;

$card-width: 560px;
$card-color: #fff;

$mobileMaxWidth: 590px;
$tabletMaxWidth: 768px;
$hTabletMaxWidth: 1024px;
$sDesktopMaxWidth: 1200px;
$mDesktopMaxWidth: 1440px;
$lDesktopMaxWidth: 1600px;
$fDesktopMaxWidth: 1920px;

// Detect mobile screen
// used as
//
// @include mobile {
//     ... mobile screen rules ...
// }
//
@mixin mobile {
  @media (max-width: $mobileMaxWidth) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tabletMaxWidth) {
    @content;
  }
}

@mixin hTablet {
  @media (max-width: $hTabletMaxWidth) {
    @content;
  }
}

@mixin sDesktop {
  @media (max-width: $sDesktopMaxWidth) {
    @content;
  }
}

@mixin mDesktop {
  @media (max-width: $mDesktopMaxWidth) {
    @content;
  }
}

@mixin lDesktop {
  @media (max-width: $lDesktopMaxWidth) {
    @content;
  }
}

@mixin fDesktop {
  @media (min-width: 1800px) {
    @content;
  }
}
