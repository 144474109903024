@import 'src/theme/theme';

.ResultPage {
  &__card {
    padding-bottom: 40px;

    &.large {
      width: 100%;
      max-width: 600px;
    }
  }

  &__whatNextCard {
    padding-bottom: 40px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 30px;

    .downloadLink {
      text-decoration: none;
    }
  }

  &__bottom {
    margin-top: 40px;
  }
}

@include fDesktop {
  .ResultPage {

    &__card {

      &.large {
        width: 100%;
        max-width: 900px;
      }
    }

    &__whatNextCard {
      padding-top: 40px;
      padding-bottom: 60px;
    }
  }
}

//@media (min-width: $tabletMaxWidth) and (max-width: $lDesktopMaxWidth) {
@include lDesktop {
  .ResultPage {
    &__card {
      width: 460px;
    }
  }
}

@include hTablet {
  .ResultPage {
    &__whatNextCard {
      width: 600px;
    }
  }
}


@include tablet {
  .ResultPage {
    &__whatNextCard {
      width: $card-width;
    }

    &__actions {
      flex-direction: column;
      margin-top: 18px;

      & > button {
        margin-top: 12px;
      }
    }
  }
}


@include mobile {
  .ResultPage {
    &__card {
      width: 100%;
    }

    &__whatNextCard {
      width: 100%;
    }

    &__actions {
      margin-top: 12px;
    }

    &__bottom {
      display: none;
      //margin-top: 24px;
    }
  }
}
