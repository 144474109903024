@import 'src/theme/theme';

.WelcomePage {
  .card {
    width: 500px;
  }

  .text {
    line-height: 28px;
  }

  .questions {
    display: flex;
    align-items: center;

    margin-top: 10px;

    color: $blue-color;
    font-family: $accent-font-family;
    font-size: 36px;
    line-height: 40px;

    span {
      margin-left: 16px;
    }
  }

  .nextBtn {
    color: $red-color;
  }

  .bottom {
    margin-top: 24px;
    opacity: 0.7;

    text-align: center;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
  }
}


@include fDesktop {
  .WelcomePage {
    .card {
      width: 660px;
    }

    .text {
      font-size: 28px;
      line-height: 36px;
    }

    .bottom {
      font-size: 28px;
      line-height: 32px;
    }

    .questions {
      margin-top: 20px;

      font-size: 40px;
    }
  }
}

@include mobile {
  .WelcomePage {
    .card {
      width: 100%;
    }

    .text {
      line-height: 20px;
    }

    .questions {
      margin-top: 20px;
    }

    .bottom {
      margin-bottom: 20px;

      font-size: 16px;
      line-height: 20px;
    }
  }
}
