@import 'src/theme/theme';

$button-color: #fff;

body .RoundedButton {
  margin: 24px 0 0;
  padding: 7px 18px 4px;
  border: 4px solid $button-color;
  background-color: $button-color;
  color: $blue-color;
  box-shadow: none;
  border-radius: 56px;

  font-family: $accent-font-family;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;

  &:hover {
    border: 4px solid $button-color;
    box-shadow: none;
    background-color: $blue-color;
    color: $button-color;
  }

  &:active {
    box-shadow: none;
  }
}


body .Button {
  margin: 0 4px;
  padding: 6px 16px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 8px;

  color: $button-color;
  font-family: $accent-font-family;
  font-size: 36px;
  line-height: 32px;

  transition: opacity .2s ease;

  &:hover {
    //background-color: rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &.medium {
    font-size: 32px;
    line-height: 28px;
  }

  &.DownloadButton {
    svg {
      margin-right: 20px;
    }
  }

  svg {
    margin-top: -4px;
    margin-right: 6px;
  }
}

body {
  .YesButton, .NoButton {
    margin-left: 10px;
    margin-right: 10px;

    svg {
      margin-top: -4px;
      margin-right: 6px;
    }

    &:hover {
      svg path {
        stroke: $button-color;
      }
    }
  }

  .YesButton {
    color: $blue-color;
  }

  .NoButton {
    color: $red-color;
  }
}

