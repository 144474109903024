@import 'src/theme/theme';

.CookiesPolicy {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  max-height: 100%;
  transition: max-height .6s ease;

  &.hidden {
    max-height: 0;
  }

  &__content {
    padding: 14px 40px;

    background-color: #fff;
    text-align: center;

    color: #262626;
    font-size: 16px;
    line-height: 24px;

  }

  a {
    color: #262626;
    text-decoration: none;
  }

  p {
    margin: 0;

    span {
      border-bottom: 1px solid #A1A1A1;
    }
  }

  &__actions {
    margin-top: 10px;
  }
}

body .btn {
  margin: 0 3px;
  padding: 4px 18px 0;

  background-color: $red-color;
  color: #fff;

  font-size: 30px;
  line-height: 30px;
}
