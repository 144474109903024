@import 'src/theme/theme';

.QuestionPage {
  overflow-x: hidden;

  .ContentLayout {
    :global(.background) {
      margin-bottom: 120px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    text-align: center;
  }

  &__card {
    min-height: 290px;

    .number {
      white-space: nowrap;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    margin-top: 40px;

    color: #fff;
    text-align: center;

    body &__back {
      position: absolute;
      top: calc(-40px - 36px);
      left: -20px;
    }
  }

  &__hint {
    max-width: 500px;
    margin-top: 24px;

    color: #fff;
    font-size: 14px;
    line-height: 20px;
  }
}

.hiddenCard {
  position: absolute;
  top: 0;
  visibility: hidden;
  max-width: 100%;
}

.cardStack {
  :global(.stack) {
    perspective: 650px !important;
    perspective-origin: 50% -60% !important;
    width: $card-width;

    transition: height .3s ease;

    :global(.stack__item) {
      max-width: 100%;
      max-height: 100%;
      background-color: initial;
      border-radius: 8px;
    }
  }

  .QuestionPage__card {
    &:global(.item_1) {
      background-color: #8697BB;
    }

    &:global(:is(.item_2, .item_3, .item_4)) {
      background-color: #355085;
    }
  }

  :global(.controls) {
    margin: 0;
  }
}


@include fDesktop {
  .QuestionPage {
  }
}

@media (min-width: $mDesktopMaxWidth) {
  .QuestionPage {
    .ContentLayout {
      margin-top: 10px;
    }
  }
}

@media (max-width: 850px) {
  .QuestionPage {
    &__hint {
      max-width: 330px;
    }
  }
}

@media (max-width: 640px) {
  .QuestionPage {
    &__bottom {
      body &__back {
        position: relative;
        top: initial;
        left: initial;
        margin-bottom: 24px;
      }
    }

    &__hint {
      max-width: 330px;
    }
  }
}

@include mobile {
  .QuestionPage {

    &__card {
      min-height: 352px;
    }

    .cardStack {
      :global(.item_1),
      :global(.item_2) {
        display: none;
      }
    }

    &__bottom {
      margin-top: 24px;
    }
  }
}


/***********************************************/
/******************** krisna *******************/
/***********************************************/


.cardStack {
  :global(.stack--krisna) {
    :global(.stack__item--reject) {
      animation: krisnaReject .7s forwards;
    }

    :global(.stack__item--accept) {
      animation: krisnaAccept .7s forwards;
    }
  }
}


@include sDesktop {
  .cardStack {
    :global(.stack--krisna) {
      :global(.stack__item--reject) {
        animation: krisnaReject .5s forwards;
      }

      :global(.stack__item--accept) {
        animation: krisnaAccept .5s forwards;
      }
    }
  }
}

@-webkit-keyframes krisnaReject {
  to {
    transform: translate3d(-50vw, 0, 0) translate3d(-60%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
}

@keyframes krisnaReject {
  to {
    transform: translate3d(-50vw, 0, 0) translate3d(-60%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
}

@-webkit-keyframes krisnaAccept {
  to {
    transform: translate3d(50vw, 0, 0) translate3d(60%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }
}

@keyframes krisnaAccept {
  to {
    transform: translate3d(50vw, 0, 0) translate3d(60%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }
}
