@import 'src/theme/theme';

.progress {
  display: flex;
  align-items: center;

  .line {
    position: relative;
    flex: 1;
    margin: 0 24px;

    svg {
      width: 100%;
    }
  }

  .check {
  }

  &.done {
    .check path {
      opacity: 1;

      transition: opacity .6s ease;
    }
  }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: clip-path .6s ease;

    path {
      opacity: 1;
    }
  }
}


@include mobile {
  .progress {
    .line {
      margin-left: 12px;
      margin-right: 12px;

      svg {
        height: 16px;
      }
    }
  }
}
