@import 'src/theme/theme';

.ContentLayout {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &__content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__bg {
    position: absolute;
    //top: 0;
    //bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    display: flex;
    max-height: 100%;

    padding: 50px 0 20px;
    box-sizing: border-box;
  }

  &__bg_image {

  }

  &__bg_image:last-child {
    text-align: right;
  }

  &__bg_spacer {
    flex: 1;
    min-width: $card-width;
  }
}


.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: $card-width;
  min-height: 240px;

  //padding: 30px 30px 40px;
  padding: 30px;
  box-sizing: border-box;

  background-color: $card-color;
  border-radius: 8px;

  text-align: center;
  font-size: 20px;
  font-weight: 400;
  white-space: pre-line;

  .CardTitle {
    margin: 0 0 10px;
    font-family: $accent-font-family;
    font-size: 42px;
    font-weight: 400;
    color: $red-color;

    &:global(.small) {
      font-size: 32px;
    }
  }

  .CardSubtitle {
    margin: 0 0 10px;
    font-family: $accent-font-family;
    font-size: 32px;
    font-weight: 400;
    color: $blue-color;
  }

  .CardContent {
    margin: 0 0 10px;

    font-weight: 400;
    line-height: 24px;
  }

  .CardContent:last-child {
    margin-bottom: 0;
  }
}


@include fDesktop {
  .ContentLayout {
    &__bg_image {
      img {
        min-height: 36vh;
      }
    }
  }

  .Card {
    width: 660px;
    min-height: 290px;

    font-size: 24px;

    .CardTitle {
      font-size: 48px;

      &:global(.small) {
        font-size: 40px;
      }
    }

    .CardSubtitle {
      font-size: 40px;
    }

    .CardContent {
      line-height: 28px;
    }
  }
}

@include mDesktop {
  .ContentLayout {
    //&__content {
    //  justify-content: flex-start;
    //}
  }
}

@include sDesktop {
  .ContentLayout {
    &__bg_spacer {
      min-width: 100px;
    }
  }
}

@include hTablet {
  .ContentLayout {
    &__bg {
      top: 0;
      bottom: 0;
      left: -44px;
      right: -44px;
    }
  }
}

@include mobile {
  .ContentLayout {
    &__bg {
      display: none;
    }
  }

  .Card {
    width: 100%;
    padding: 30px 18px;


    font-size: 18px;

    .CardTitle {
      font-size: 36px;

      &:global(.small) {
        font-size: 28px;
      }
    }

    .CardContent {
      line-height: 22px;
    }
  }
}
