@import 'src/theme/theme';

.AboutPage {

  .wrapper {
    :global(.background) {
      position: fixed;
      top: 70px;
      width: 100vw;
      min-height: 70vh;
      max-height: calc(100vh - 70px - 160px);
      padding-left: 44px;
      padding-right: 44px;
    }
  }

  &__card {
    align-items: stretch;
    width: 100%;
    max-width: 800px;
    padding-bottom: 40px;

    text-align: left;

    .title {
      text-align: center;
      margin-bottom: 30px;
      //margin-bottom: 50px;
    }

    .text {
      margin-bottom: 20px;
    }

    .subtitle {
      margin-top: 30px;
      margin-bottom: 30px;

      font-weight: 700;
    }

    .resultTitle {
      margin-top: 10px;
      margin-bottom: 20px;

      color: $red-color;
      font-family: $accent-font-family;
      font-weight: 400;
      font-size: 24px;
    }

    .borderedText {
      margin: 10px 0 30px;
      color: $blue-color;

      text-align: center;
      font-family: $accent-font-family;
      font-size: 36px;
      line-height: 32px;

      &__divider {
        height: 4px;
        margin: 20px 0;
        background-color: $blue-color;
        border-radius: 2px;
      }

      &__divider:first-child {
        margin-top: 0;
      }

      &__divider:last-child {
        margin-bottom: 0;
      }
    }

    .link {
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(3, 36, 103, 0.3);
    }

    a {
      color: $blue-color;
      text-decoration: none;

      &:hover {
        //text-decoration: underline;
      }
    }
  }

  .divider {
    height: 1px;
    margin: 10px 0 30px;
    background-color: #000;
    opacity: 0.2;
  }

  .testInfo {
    position: relative;
    z-index: 2000;
    color: $blue-color;
    border-bottom: 1px dashed $blue-color;
    font-weight: 700;
    cursor: pointer;
  }
}

body .testTooltip {
  width: 460px;
  max-width: 100%;
  margin-top: 6px;
  padding: 30px 30px 40px;
  box-sizing: border-box;

  overflow: visible;

  border-radius: 8px;
  background-color: $card-color;

  font-size: 20px;
  line-height: 24px;

  .content {
    .arrow {
      top: -7px;
      left: 50%;
      width: 14px;
      height: 7px;
      overflow: hidden;
      position: absolute;
      box-sizing: border-box;
      color: #fff;

      &:before {
        width: 100%;
        height: 100%;
        margin: auto;
        content: "";
        display: block;
        transform: rotate(45deg);
        background-color: currentColor;
        transform-origin: 0 100%;
      }
    }

    & > div {
      height: 20px;
    }
  }

  .tooltipTitle {
    font-weight: 700;
  }
}

@include fDesktop {
  .AboutPage {

    &__card {
      max-width: 1000px;
      padding-top: 40px;
      padding-bottom: 60px;

      .title {
        margin-bottom: 50px;
        font-size: 49px;
        line-height: 42px;
      }
    }
  }
}

@include hTablet {
  .AboutPage {

    .wrapper {
      :global(.background) {
        width: calc(100vw + 88px);
      }
    }
  }
}

@include mobile {

  body .testTooltip {
    max-width: calc(100% - 32px);
    padding: 20px 20px 30px;
  }
}
