@import 'src/theme/theme';

@font-face {
  font-family: 'Helvetica';
  src: url('assets/fonts/Helvetica.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pribambas';
  src: url('assets/fonts/Pribambas.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Helvetica', 'Roboto', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  background-color: $background-color;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
