@import "src/theme/theme";

.PageLayout {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  height: 100%;

  color: $text-color;

  .content {
    flex: 1;
    //margin: 32px 44px 0;
    margin: 44px 44px 0;
  }

  &__title {
    display: none;
    margin-top: 42px;
    text-align: center;
  }
}

body .AppBarButton {
  margin: 0 4px;
  padding: 6px 16px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 8px;

  font-family: inherit;
  font-size: 16px;
  text-transform: none;

  &:active {
    box-shadow: none;
  }

  &:hover {
    //background-color: rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    background-color: transparent;
    box-shadow: none;
  }
}

.AppBar {
  z-index: 2000;
  position: relative;
  display: flex;
  padding: 30px 24px 0 0;

  color: #fff;

  &.hidden {
    visibility: hidden;
  }

  &__logoWrapper {
    position: absolute;
    top: 10px;
    width: 240px;
    z-index: 100;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
  }

  &__menu {
    display: none;

    &__btn:global(.MuiButtonBase-root) {
      margin: -12px;
      color: inherit;
    }
  }
}

.PageTitle {
  display: inline-block;
  width: 300px;
  color: #fff;
  text-align: center;
  font-family: $accent-font-family;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: -4px;

  cursor: pointer;
}

.Drawer {
  .paper {
    background-color: $background-color;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    margin-top: 16px;

    .AppBarButton {
      justify-content: flex-start;
      margin: 2px 0;
      padding: 0 8px;

      font-size: 20px;
    }
  }

  .PageLayout {
    .content {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .Footer {
    margin-left: 32px;
    margin-right: 32px;
  }
}

.Footer {
  position: relative;
  margin: 52px 44px 0;
  padding-bottom: 34px;

  border-top: 1px solid rgba(255, 255, 255, 0.2);

  font-size: 14px;
  white-space: pre-line;

  .accent {
    color: rgba(255, 255, 255, 0.4); /*$accent-text-color;*/
  }

  &__lineText {
    position: relative;
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;

    color: $accent-text-color;
    font-family: $accent-font-family;
    font-size: 29px;
    text-align: center;

    span {
      padding: 0 6px;
      background-color: $background-color;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 20px;

    line-height: 20px;

    color: #fff;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &__info {
      position: relative;
      max-width: 800px;

      .fundLink {
        //position: absolute;
        margin-left: 6px;

        white-space: nowrap;

        svg {
          height: 10px;
          opacity: 0.3;
        }
      }
    }

    &__corp2 {
      margin-bottom: 6px;
      line-height: 24px;
      white-space: initial;
    }

    &__fond {
      margin-top: 16px;
      line-height: 24px;

      a {
        white-space: nowrap;
      }

      span {
        display: inline-block;
        margin-right: 11px;
        //white-space: nowrap;
      }
    }

    .sponsor {
      position: relative;
      bottom: -26px;
      margin-top: -28px;
      margin-left: 70px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      text-decoration: none !important;
      white-space: nowrap;

      &:hover {
        opacity: 0.6;
      }

      &__logo {
        width: 130px;
        margin-top: 5px;
      }
    }
  }
}

.ImageWrap {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}


@include fDesktop {
  .PageTitle {
    width: 360px;
    font-size: 48px;
  }
}

@include lDesktop {
  .PageLayout {
    .content {
      margin-top: 48px;
    }
  }
}

@include sDesktop {
  .PageLayout {
    .content {
    }
  }
}

@include hTablet {
  .PageLayout {
    .content {
    }
  }

  .Footer {
    padding-bottom: 22px;

    &__content {
      &__info {
        max-width: 660px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .Footer {
    &__content {
      &__info {
        //max-width: 590px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .Footer {
    &__lineText {
      span {
        max-width: 407px;
        margin-bottom: -12px;
        padding: 0 16px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .PageLayout {
    &__title {
      display: block;
    }
  }

  .AppBar {
    &__content {
      display: none;
    }
  }
}

@include tablet {
  .PageLayout {
    .content {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 40px;
    }
  }

  .Footer {
    padding-bottom: 28px;
    margin-left: 30px;
    margin-right: 30px;

    &__content {
      &__info {
        max-width: 590px;
      }
    }
  }
}

@include mobile {
  .PageLayout {
    &__title {
      margin-top: 28px;
    }

    .content {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 20px;
      padding-top: 0;
    }
  }

  .AppBar {
    padding: 24px 14px 0 0;

    &__logoWrapper {
      width: 174px;
    }

    &__actions {
      display: none;
    }

    &__menu {
      display: block;
    }
  }

  .Footer {
    margin-top: 30px;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 40px;

    &__lineText {
      position: initial;
      top: 0;
      margin: 30px 0 40px;
      padding-bottom: 0;

      font-size: 25px;

      span {
        max-width: 360px;
        padding: 0;
      }
    }

    &__content {
      flex-wrap: wrap;


      &__corp2 {
        white-space: pre-line;
      }

      &__fond {
        position: relative;
        margin-top: 140px;
        max-width: 400px;
      }

      .sponsor {
        position: initial;
        bottom: 0;
        align-items: flex-start;
        width: 100%;
        margin-top: -220px;
        margin-left: 0;
      }
    }
  }
  .PageTitle {
    width: initial;
  }
}
